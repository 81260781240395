<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>            
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Calendário escolar</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0">
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">                  
                  Calendário <span>escolar</span>
                </h1>
                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row mt-4">     
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.exibir_anos"
                    class="col-12 mt-2"
                  >
                    <div class="row">
                      <div class="col-12">
                        <h4 class="text-secondary">
                          Selecione o calendário
                        </h4>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div
                        v-for="(ano, index) in fastFeriados.anos"
                        :key="index"
                        class="col-sm-12 col-md-12 col-lg-3 text-center"
                      >
                        <div
                          :class="fastAnoSelecionado == ano.ano_base ? 'fast-cursor-pointer p-4 rounded shadow border text-info fast-ano-selecionado' : 'fast-cursor-pointer p-4 rounded shadow border text-info'"
                          @click="getfastFeriados($route.params.id_plataforma, ano.ano_base)"
                        >
                          <h1 class="mt-2">
                            {{ ano.ano_base }}
                          </h1>
                          <h6>{{ ano.dias }} dias não letivos</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </transition>           
                <transition name="slide-fade">
                  <div
                    v-if="fastNavegacao.exibir_calendario"
                    class="col-12 mt-4"
                  >
                    <div class="row">
                      <div class="col-sm-12 col-md-12 col-lg-12">
                        <h4 class="text-secondary">
                          Clique nos dias e defina os dias não letivos para o ano de {{ fastAnoSelecionado }}
                        </h4>
                      </div>                      
                    </div>
                    <div class="row mt-2">
                      <div class="col-sm-12 col-md-12 col-lg-6">
                        <span>Legenda:</span>&nbsp;
                        <b-badge style="background-color: #ff8498">
                          Hoje
                        </b-badge> &nbsp;
                        <b-badge style="background-color: #66b3cc">
                          Dia selecionado
                        </b-badge> &nbsp;
                        <b-badge style="background-color: #E10000">
                          Dia não letivo
                        </b-badge>
                      </div>
                      <div class="col-sm-12 col-md-12 col-lg-6 text-right">
                        <button
                          class="btn btn-sm btn-primary pt-0 pb-0"
                          @click="showModal('modalOpcoesAutomaticas')"
                        >
                          <small>Opções automáticas</small>
                        </button>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div
                        v-for="(i, index) in fastCalendarios"
                        :key="index"
                        class="col-sm-12 col-md-12 col-lg-4 mb-4"
                      >
                        <div class="row">
                          <div class="col-12">
                            <FunctionalCalendar
                              :ref="i.ref"
                              v-model="i.calendarData"
                              :new-current-date="i.calendarConfigs.newCurrentDate"
                              :configs="i.calendarConfigs"
                              @opened="verificaCal(i)"
                              @dayClicked="selecionaDiaCalendario"
                            />                   
                          </div>
                        </div>
                        <div
                          v-if="i.calendarConfigs.markedDates.length"
                          class="row"
                        >
                          <div class="col-12 mt-2">
                            <strong>Dias não letivos de {{ retornaNomeMes(i.calendarData.currentDate) }}</strong>
                          </div>
                          <div
                            v-for="(d, index2) in i.calendarConfigs.markedDates"
                            :key="index2"
                            class="col-12"
                          >
                            - {{ retornaFeriadoDescricao(d.date) }}
                          </div>
                        </div>
                      </div>                
                    </div>
                  </div>
                </transition>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">               
                <a
                  class="btn btn-red-hollow"
                  :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
                >
                  <b-icon-arrow-return-left />
                  Voltar para menu
                </a>                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
    <modal
      name="modalFeriado"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>{{ `${fastFeriadoNovo.dia}/${fastFeriadoNovo.mes}/${fastFeriadoNovo.ano_base}` }}</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalFeriado')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <h6
                v-if="fastRemoverFeriado"
                class="text-danger"
              >
                A data já está definida como dia não letivo.
              </h6>
              <div class="row mt-4">
                <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label>Nome da data<small class="text-danger">*</small></label>
                  <input
                    v-model="fastFeriadoNovo.nome_feriado"
                    :class="!fastFeriadoNovo.nome_feriado ? 'form-control border border-danger' : 'form-control'"
                    type="text"
                    class="form-control"
                    maxlength="255"
                  >
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                  <label>Data fixa</label>
                  <div class="fd-app-meus-cursos mt-0 ml-1">
                    <div class="fd-app-curso-aulas-list mt-0">
                      <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                        <label class="switch">
                          <input
                            v-model="fastFeriadoNovo.data_fixa"
                            checked="checked"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                  <label>Data flutuante</label>
                  <div class="fd-app-meus-cursos mt-0 ml-1">
                    <div class="fd-app-curso-aulas-list mt-0">
                      <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                        <label class="switch">
                          <input
                            v-model="fastFeriadoNovo.data_flutuante"
                            checked="checked"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                  <label>Plataformas filhas</label>
                  <div class="fd-app-meus-cursos mt-0 ml-1">
                    <div class="fd-app-curso-aulas-list mt-0">
                      <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                        <label class="switch">
                          <input
                            v-model="fastFeriadoNovo.deriva_plataforma_filha"
                            checked="checked"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  v-if="fastBtnLoading"
                  href="#"
                  type="button"
                  class="btn btn-sm btn-primary ml-1 mr-1"
                >
                  Aguarde...
                </button>
                <button
                  v-else-if="!fastBtnLoading && !fastRemoverFeriado"
                  href="#"
                  type="button"
                  class="btn btn-sm btn-primary ml-1 mr-1"
                  @click="criarFastFeriado()"
                >
                  Confirmar dia não letivo
                </button> 
                <div v-else-if="!fastBtnLoading && fastRemoverFeriado">
                  <button
                    href="#"
                    type="button"
                    class="btn btn-sm btn-primary ml-1 mr-1"
                    @click="editarFastFeriado()"
                  >
                    Salvar alterações
                  </button> 
                  <button
                    href="#"
                    type="button"
                    class="btn btn-sm btn-danger ml-1 mr-1"
                    @click="excluirFastFeriado()"
                  >
                    Remover dia
                  </button> 
                </div>             
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalFeriadosNacionais"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Inserir feriados nacionais?</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalFeriadosNacionais')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <h6>Feriados que serão adicionados:</h6>
              <ul style="list-style-position: inside">
                <li
                  v-for="(f, index) in fastFeriadosNacionais"
                  :key="index"
                >
                  {{ `${f.dia}/${f.mes}/${fastAnoSelecionado} - ${f.nome_feriado}` }}
                </li>
              </ul>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  v-if="!fastBtnLoading"
                  href="#"
                  type="button"
                  class="btn btn-danger"
                  @click.prevent="inserFastFeriadosNacionais()"
                >
                  Confirmar
                </button>
                <button
                  v-else
                  href="#"
                  type="button"
                  class="btn btn-danger"
                >
                  <b-icon
                    icon="gear-fill"
                    animation="spin"
                  /> Aguarde...
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalOpcoesAutomaticas"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthMd"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-10 pl-4 pr-4">
          <h4>Escolha uma Opção</h4>
        </div>
        <div class="col-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalOpcoesAutomaticas')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="row">
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <div
                    class="card fast-cursor-pointer"
                    @click.prevent="showModal('modalFeriadosNacionais', 'modalOpcoesAutomaticas')"
                  >
                    <div class="card-body text-center">
                      <h6 class="card-title ">
                        Feriados nacionais
                      </h6> 
                      <b-icon-calendar3-week font-scale="3" /> 
                      <small class="d-block card-text mt-2">
                        Insere automaticamente os feriados nacionais fixos
                      </small>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <div
                    class="card fast-cursor-pointer"
                    @click.prevent="showModal('modalDiaPersonalizadoNovo', 'modalOpcoesAutomaticas')"
                  >
                    <div class="card-body text-center">
                      <h6 class="card-title ">
                        + Dia personalizado
                      </h6>  
                      <b-icon-calendar-plus font-scale="3" />
                      <small class="d-block card-text mt-2">
                        Insere um dia da semana personalizado ao longo do ano
                      </small>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-4">
                  <div
                    class="card fast-cursor-pointer"
                    @click.prevent="showModal('modalDiaPersonalizadoExcluir', 'modalOpcoesAutomaticas')"
                  >
                    <div class="card-body text-center">
                      <h6 class="card-title ">
                        - Dia personalizado
                      </h6>  
                      <b-icon-calendar-minus font-scale="3" />
                      <small class="d-block card-text mt-2">
                        Remove um dia da semana personalizado ao longo do ano
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalDiaPersonalizadoNovo"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Dia personalizado</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalDiaPersonalizadoNovo')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label>Dia da semana</label>
                  <select
                    v-model="fastDiaSemanaEscolhido"
                    class="form-control"
                  >
                    <option
                      v-for="d in fastDiasSemana"
                      :key="d.day"
                      :value="d.day"
                    >
                      <span v-if="d.day == 0 || d.day == 6">Todo {{ d.name }}</span>
                      <span v-else>Toda {{ d.name }}</span>                      
                    </option>
                  </select>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label>Nome da data<small class="text-danger">*</small></label>
                  <input
                    v-model="fastFeriadoNovo.nome_feriado"
                    :class="!fastFeriadoNovo.nome_feriado ? 'form-control border border-danger' : 'form-control'"
                    type="text"
                    class="form-control"
                    maxlength="255"
                  >
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                  <label>Data fixa</label>
                  <div class="fd-app-meus-cursos mt-0 ml-1">
                    <div class="fd-app-curso-aulas-list mt-0">
                      <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                        <label class="switch">
                          <input
                            v-model="fastFeriadoNovo.data_fixa"
                            checked="checked"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                  <label>Data flutuante</label>
                  <div class="fd-app-meus-cursos mt-0 ml-1">
                    <div class="fd-app-curso-aulas-list mt-0">
                      <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                        <label class="switch">
                          <input
                            v-model="fastFeriadoNovo.data_flutuante"
                            checked="checked"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 mb-4 text-center">
                  <label>Plataformas filhas</label>
                  <div class="fd-app-meus-cursos mt-0 ml-1">
                    <div class="fd-app-curso-aulas-list mt-0">
                      <div class="fd-app-curso-aulas-list-item p-0 shadow-none border-0 bg-transparent text-center">
                        <label class="switch">
                          <input
                            v-model="fastFeriadoNovo.deriva_plataforma_filha"
                            checked="checked"
                            type="checkbox"
                          >
                          <span class="slider round" />
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  v-if="fastBtnLoading"
                  href="#"
                  type="button"
                  class="btn btn-sm btn-primary ml-1 mr-1"
                >
                  Aguarde...
                </button>
                <button
                  v-else-if="!fastBtnLoading"
                  href="#"
                  type="button"
                  class="btn btn-sm btn-primary ml-1 mr-1"
                  @click="criarFastFeriadoPersonalizado()"
                >
                  Confirmar dia não letivo
                </button>            
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="modalDiaPersonalizadoExcluir"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Excluir dia personalizado</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('modalDiaPersonalizadoExcluir')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-12 p-4">
          <div class="row">
            <div class="col-12">
              <div class="row mt-4">
                <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
                  <label>Dia da semana</label>
                  <select
                    v-model="fastDiaSemanaEscolhido"
                    class="form-control"
                  >
                    <option
                      v-for="d in fastDiasSemana"
                      :key="d.day"
                      :value="d.day"
                    >
                      <span v-if="d.day == 0 || d.day == 6">Todo {{ d.name }}</span>
                      <span v-else>Toda {{ d.name }}</span>                      
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-12 mt-4">
              <div class="form-group mb-0">
                <button
                  v-if="fastBtnLoading"
                  href="#"
                  type="button"
                  class="btn btn-sm btn-danger ml-1 mr-1"
                >
                  Aguarde...
                </button>
                <button
                  v-else-if="!fastBtnLoading"
                  href="#"
                  type="button"
                  class="btn btn-sm btn-danger ml-1 mr-1"
                  @click="excluirFastFeriadoPersonalizado()"
                >
                  Remover dia personalizado
                </button>            
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import { FunctionalCalendar } from "vue-functional-calendar";

export default {
  name: "HomeInternoSecretariaFeriado",
  components: { FunctionalCalendar },

  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "100%" : "30%",
      modalWidthMd: this.isMobile() ? "100%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      fastAnoAtual: new Date().getFullYear(),
      fastAnoSelecionado: "",
      fastBtnLoading: false,
      fastNavegacao: {
        exibir_anos: true,
        exibir_calendario: false
      },
      fastCalendarios: [],
      fastFeriados: {
        total: [],
        filtro: [],
        paginacao: [],
        anos: [],
        loading: true
      },
      fastDataSelecionada: "",
      fastRemoverFeriado: false,
      fastFeriadoNovo: {
        id_calendario: 0,
        nome_feriado: "",
        ano_base: 0,
        id_plataforma: this.$route.params.id_plataforma,
        data_fixa: false,
        data_flutuante: false,
        dia: 0,
        mes: 0,
        deriva_plataforma_filha: false
      },
      fastFeriadosNacionais: [
        { dia: "01", mes: "01", ano_base: "", nome_feriado: "Início do ano civil" },
        { dia: "21", mes: "04", ano_base: "", nome_feriado: "Tiradentes" },
        { dia: "01", mes: "05", ano_base: "", nome_feriado: "Dia do trabalhador" },
        { dia: "07", mes: "09", ano_base: "", nome_feriado: "Independência do Brasil" },
        { dia: "12", mes: "10", ano_base: "", nome_feriado: "Nossa Senhora Aparecida" },
        { dia: "02", mes: "11", ano_base: "", nome_feriado: "Finados" },
        { dia: "15", mes: "11", ano_base: "", nome_feriado: "Proclamação da República" },
        { dia: "25", mes: "12", ano_base: "", nome_feriado: "Natal" }
      ],
      fastDiasSemana: [
        {day: 0, name: "domingo"},
        {day: 1, name: "segunda-feira"},
        {day: 2, name: "terça-feira"},
        {day: 3, name: "quarta-feira"},
        {day: 4, name: "quinta-feira"},
        {day: 5, name: "sexta-feira"},
        {day: 6, name: "sábado"},
      ],
      fastDiaSemanaEscolhido: 0,
      /*
      fastFeriadoEditar: {
        id_calendario: 0,
        nome_feriado: "",
        ano_base: "",
        id_plataforma: 0,
        data_fixa: "",
        data_flutuante: "",
        dia: "",
        mes: "",
        deriva_plataforma_filha: ""
      },
      calendarData: {
        currentDate: "",
        dateRange: {
          end: "",
          start: ""
        },
        multipleDateRange: [],
        selectedDate: "",
        selectedDateTime: false,
        selectedDates: [],
        selectedDatesItem: "",
        selectedHour: "00",
        selectedMinute: "00"
      },
      calendarConfigs: {
        sundayStart: true,
        dateFormat: "dd/mm/yyyy",
        isDatePicker: true,
        isDateRange: false,
        markedDates: [],
        //limits: {min: '1/1/2021', max: '31/12/2021'},
        //isMultiple: true,
        //calendarsCount: 12,
        //isSeparately: true
      },
      */
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            this.getfastFeriadosAnos(this.$route.params.id_plataforma);
            this.$store.state.fastCarregando = false;
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }          
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.$store.state.fastCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {
    async getfastFeriadosAnos(id_plataforma){
      this.alteraFastNavegacao('exibir_anos')   
      this.promiseGetFastApi("api/fast_secretaria_feriado/lista_anos", "id_plataforma=" + id_plataforma).then(res => {
        this.fastFeriados.anos = []
        let anoAtualIncluido = false
        let anoFuturoIncluido = false
        if (res.length) {
          res.forEach(a => {
            if (a.ano_base == this.fastAnoAtual) anoAtualIncluido = true            
            if (a.ano_base == parseInt(this.fastAnoAtual) + 1) anoFuturoIncluido = true            
          })
          this.fastFeriados.anos = res
        }     
        if (!anoAtualIncluido) this.fastFeriados.anos.push({ano_base: this.fastAnoAtual, dias: 0})
        if (!anoFuturoIncluido) this.fastFeriados.anos.push({ano_base: parseInt(this.fastAnoAtual) + 1, dias: 0})
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar anos", "error");
      })
    },
    getDaysInMonth(m, y) {
        return m===2 ? y & 3 || !(y%25) && y & 15 ? 28 : 29 : 30 + (m+(m>>3)&1);
    },
    async getfastFeriados(id_plataforma, ano_base){
      this.fastAnoSelecionado = ano_base
      this.fastNavegacao.exibir_calendario = false 
      this.fastFeriados.loading = true
      
      this.promiseGetFastApi("api/fast_secretaria_feriado/lista", "id_plataforma=" + id_plataforma + "&ano_base=" + ano_base).then(obj => {
        if (obj.length) {
          this.fastFeriados.total = obj
          this.fastFeriados.filtro = obj
        }
        else {
          this.fastFeriados.total = []
          this.fastFeriados.filtro = []
        }        
        this.fastNavegacao.exibir_calendario = true 
        this.fastCalendarios = []
        // Percorre os 12 meses
        for (let i = 1; i <= 12; i++) {
          // Array de feriados
          let markedDates = []
          // Varre feriados do ano e verifica se pertence ao mês
          this.fastFeriados.total.forEach(f => {            
            if (f.mes == i) markedDates.push({ date: `${f.dia}/${f.mes}/${f.ano_base}`, class: "cal-feriado-marcado", dia: parseInt(f.dia) });            
          })
          // Reeordena (não está reeordando os que tem 0 na frente)   
          markedDates.sort((a,b) => (a.dia > b.dia) ? 1 : ((b.dia > a.dia) ? -1 : 0))
          console.log("markedDates", markedDates)
          // Cria objeto com parâmetros do calendário
          let obj = {
              calendarData: {
                currentDate: new Date(`${ano_base}-${i}-1`),
                dateRange: {
                  end: `${this.getDaysInMonth(i, ano_base)}/${i}/${ano_base}`,
                  start: `1/${i}/${ano_base}`
                },
                multipleDateRange: [],
                selectedDate: false,
                selectedDateTime: false,
                selectedDates: [],
                selectedDatesItem: "",
                selectedHour: "00",
                selectedMinute: "00",
              },
              calendarConfigs: {                
                sundayStart: true,
                dateFormat: "dd/mm/yyyy",
                isDatePicker: true,
                isDateRange: false,
                limits: {min: `1/${i}/${ano_base}`, max: `${this.getDaysInMonth(i, ano_base)}/${i}/${ano_base}`},   
                changeMonthFunction: false,
                changeYearFunction: false,                       
                newCurrentDate: new Date(`${ano_base}-${i}-1`),
                markedDates: markedDates,
              } ,
              ref: `fastCalendario_${i}_${ano_base}`
          }    
          // Insere objeto no array que será exibido pelo v-for no html
          this.fastCalendarios.push(obj)          
        }

        this.fastFeriados.loading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar feriados", "error");
        this.fastFeriados.loading = false
      })
    },
    retornaNomeMes(data){
      const meses = ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro']
      return meses[data.getMonth()]
    },
    retornaFeriadoDescricao(data){      
      let descricao = "Sem descrição"
      this.fastFeriados.total.forEach(f => {
        if (data == `${f.dia}/${f.mes}/${f.ano_base}`) descricao = f.nome_feriado
      })

      let dia = data.split("/")[0].length == 1 ? "0" + data.split("/")[0] : data.split("/")[0]
      let mes = data.split("/")[1].length == 1 ? "0" + data.split("/")[1] : data.split("/")[1]
      let ano = data.split("/").pop()

      return `${dia}/${mes}/${ano} - ${descricao}`
    },
    selecionaDiaCalendario(cal){
      const anoSelecionado = cal.date.split("/").pop()
      if (anoSelecionado == this.fastAnoSelecionado) {
        this.fastFeriadoNovo = {
          id_calendario: 0,
          nome_feriado: "",
          ano_base: "",
          id_plataforma: this.$route.params.id_plataforma,
          data_fixa: false,
          data_flutuante: false,
          dia: "",
          mes: "",
          deriva_plataforma_filha: false
        }
        let dia = cal.day.toString()
        this.fastFeriadoNovo.dia = dia.length == 1 ? '0' + dia : cal.day
        this.fastFeriadoNovo.mes = cal.date.split("/")[1].length == 1 ? '0' + cal.date.split("/")[1].toString() : cal.date.split("/")[1]
        this.fastFeriadoNovo.ano_base = cal.date.split("/").pop()
        this.fastDataSelecionada = `${this.fastFeriadoNovo.ano_base}-${this.fastFeriadoNovo.mes}-${this.fastFeriadoNovo.dia}`

        this.fastRemoverFeriado = false
        this.fastFeriados.total.forEach(f => {
          // Se ja estiver marcado como feriado
          if (`${f.ano_base}-${f.mes}-${f.dia}` == `${this.fastFeriadoNovo.ano_base}-${parseInt(this.fastFeriadoNovo.mes)}-${parseInt(this.fastFeriadoNovo.dia)}`) {
            this.fastRemoverFeriado = true
            this.fastFeriadoNovo.id_calendario = f.id_calendario
            this.fastFeriadoNovo.data_fixa = f.data_fixa
            this.fastFeriadoNovo.data_flutuante = f.data_flutuante
            this.fastFeriadoNovo.deriva_plataforma_filha = f.deriva_plataforma_filha
            this.fastFeriadoNovo.nome_feriado = f.nome_feriado
            this.fastFeriadoNovo.id_plataforma = f.id_plataforma
          }
        })
        this.showModal('modalFeriado')
      }      
    },
    retornaDiasSemana(year, day) {
      var date = new Date(year, 0, 1);
      while (date.getDay() != day) {
        date.setDate(date.getDate() + 1);
      }
      var days = [];
      while (date.getFullYear() == year) {
        var m = date.getMonth() + 1;
        var d = date.getDate();
        days.push(
          year + '-' +
          (m < 10 ? '0' + m : m) + '-' +
          (d < 10 ? '0' + d : d)
        );
        date.setDate(date.getDate() + 7);
      }
      return days;
    },
    /*
    filtroAno(e) {
      let text = e.target.value;
      this.fastFeriados.filtro = this.fastFeriados.total.filter(
        (e) => {
          return (
            e.ano_base.toLowerCase().indexOf(text.toLowerCase()) != "-1" 
          );
        }
      );
    },
    selecionaDia(cal){
      let dia = cal.day.toString()
      this.fastFeriadoNovo.dia = dia.length == 1 ? '0' + dia : cal.day
      this.fastFeriadoNovo.mes = cal.date.split("/")[1].length == 1 ? '0' + cal.date.split("/")[1].toString() : cal.date.split("/")[1]
      this.fastFeriadoNovo.ano_base = cal.date.split("/").pop()
      this.fastDataSelecionada = `${this.fastFeriadoNovo.ano_base}-${this.fastFeriadoNovo.mes}-${this.fastFeriadoNovo.dia}`

      this.fastRemoverFeriado = false
      this.fastFeriados.total.forEach(f => {
        if (`${f.ano_base}-${f.mes}-${f.dia}` == `${this.fastFeriadoNovo.ano_base}-${parseInt(this.fastFeriadoNovo.mes)}-${parseInt(this.fastFeriadoNovo.dia)}`) {
          console.log(`${f.ano_base}-${f.mes}-${f.dia}`, `${this.fastFeriadoNovo.ano_base}-${parseInt(this.fastFeriadoNovo.mes)}-${parseInt(this.fastFeriadoNovo.dia)}`)
          this.fastRemoverFeriado = true
          this.fastFeriadoNovo.id_calendario = f.id_calendario
          this.fastFeriadoNovo.data_fixa = f.data_fixa
          this.fastFeriadoNovo.data_flutuante = f.data_flutuante
        }
      })
      console.log("this.fastFeriadoNovo", this.fastFeriadoNovo)
    },
    selecionaDiaInput(){      
      this.fastFeriadoNovo.dia = this.fastDataSelecionada.split("-")[2]
      this.fastFeriadoNovo.mes = this.fastDataSelecionada.split("-")[1]
      this.fastFeriadoNovo.ano_base = this.fastDataSelecionada.split("-")[0]
      //this.calendarData.currentDate = new Date(this.fastDataSelecionada)
      //this.calendarData.selectedDate = true

      this.$refs.Calendar.ChooseDate(`${this.fastFeriadoNovo.dia}/${this.fastFeriadoNovo.mes}/${this.fastFeriadoNovo.ano_base}`)
      this.calendarData.selectedDate = `${parseInt(this.fastFeriadoNovo.dia)}/${parseInt(this.fastFeriadoNovo.mes)}/${this.fastFeriadoNovo.ano_base}`

      console.log("calendarData", this.calendarData)
      console.log("this.fastDataSelecionada", this.fastDataSelecionada)      
    },
    exibeModalEditarFeriado(feriado){
      this.alteraFastNavegacao('criar')  
      //this.fastFeriadoNovo = feriado
      //this.$refs.Calendar.ChooseDate(`${this.fastFeriadoNovo.dia}/${this.fastFeriadoNovo.mes}/${this.fastFeriadoNovo.ano_base}`)
      //this.calendarData.selectedDate = `${parseInt(this.fastFeriadoNovo.dia)}/${parseInt(this.fastFeriadoNovo.mes)}/${this.fastFeriadoNovo.ano_base}`      
    },
    */
    async criarFastFeriado(){
      this.fastBtnLoading = true  
      let erros = []
      if (!this.fastFeriadoNovo.nome_feriado) erros.push("O nome do dia é obrigatório")

      if (erros.length) {
          erros.forEach(e => this.exibeToasty(e, "error"))
          this.fastBtnLoading = false
      } else {        
          this.promisePostFastApi(this.fastFeriadoNovo, "api/fast_secretaria_feriado/insere").then((res) => {            
            this.fastBtnLoading = false
            this.exibeToasty("Dias salvo com sucesso", "success")
            this.hideModal('modalFeriado')
            this.getfastFeriados(this.$route.params.id_plataforma, this.fastFeriadoNovo.ano_base)
            
            this.fastFeriadoNovo = {
              id_calendario: 0,
              nome_feriado: "",
              ano_base: "",
              id_plataforma: this.$route.params.id_plataforma,
              data_fixa: false,
              data_flutuante: false,
              dia: "",
              mes: "",
              deriva_plataforma_filha: false
            }
          }).catch((e) => {
              this.exibeToasty("Erro ao salvar dia", "error");
              this.fastBtnLoading = false
          });
      }   
    },  
    async inserFastFeriadosNacionais(){
      this.fastBtnLoading = true 

      this.fastFeriadoNovo = {
        id_calendario: 0,
        nome_feriado: "",
        ano_base: 0,
        id_plataforma: this.$route.params.id_plataforma,
        data_fixa: true,
        data_flutuante: false,
        dia: 0,
        mes: 0,
        deriva_plataforma_filha: false
      }

      this.fastFeriadosNacionais.forEach((f, index) => {
        this.fastFeriadoNovo.nome_feriado = f.nome_feriado
        this.fastFeriadoNovo.dia = f.dia
        this.fastFeriadoNovo.mes = f.mes
        this.fastFeriadoNovo.ano_base = this.fastAnoSelecionado

        this.promisePostFastApi(this.fastFeriadoNovo, "api/fast_secretaria_feriado/insere").then((res) => {      
          if ((index + 1) == this.fastFeriadosNacionais.length) {
            this.exibeToasty("Feriados inseridos com sucesso", "success")
            this.hideModal('modalFeriadosNacionais')
            this.getfastFeriados(this.$route.params.id_plataforma, this.fastFeriadoNovo.ano_base)
            this.fastFeriadoNovo = {
              id_calendario: 0,
              nome_feriado: "",
              ano_base: "",
              id_plataforma: this.$route.params.id_plataforma,
              data_fixa: false,
              data_flutuante: false,
              dia: "",
              mes: "",
              deriva_plataforma_filha: false
            }
            this.fastBtnLoading = false
          }
          
        }).catch((e) => {
            this.exibeToasty("Erro ao inserir feriados", "error");
            this.fastBtnLoading = false
        });
      })
      
    }, 
    async criarFastFeriadoPersonalizado(){
      let erros = []
      if (!this.fastFeriadoNovo.nome_feriado) erros.push("O nome do dia é obrigatório")

      if (erros.length) {
          erros.forEach(e => this.exibeToasty(e, "error"))
          this.fastBtnLoading = false
      } else { 
        let datas = this.retornaDiasSemana(this.fastAnoSelecionado, this.fastDiaSemanaEscolhido) 
        this.fastBtnLoading = true 
                
        datas.forEach(async (f, index) => {
          let fastFeriadoNovo = Object.assign({}, this.fastFeriadoNovo);
          fastFeriadoNovo.ano_base = this.fastAnoSelecionado
          fastFeriadoNovo.dia = f.split("-")[2]
          fastFeriadoNovo.mes = f.split("-")[1]
          
          await this.promiseCriarFastFeriado(fastFeriadoNovo);         

          if ((index + 1) == datas.length) {
            this.exibeToasty("Dias inseridos com sucesso", "success")
            this.hideModal('modalDiaPersonalizadoNovo')
            this.getfastFeriados(this.$route.params.id_plataforma, this.fastAnoSelecionado)           
            this.fastBtnLoading = false
          }    
        })
      }
    },
    async excluirFastFeriadoPersonalizado(){      
      let datas = this.retornaDiasSemana(this.fastAnoSelecionado, this.fastDiaSemanaEscolhido) 
      this.fastBtnLoading = true 
              
      datas.forEach(async (f, index) => {
        let fastFeriadoNovo = Object.assign({}, this.fastFeriadoNovo);
        fastFeriadoNovo.ano_base = this.fastAnoSelecionado
        fastFeriadoNovo.dia = f.split("-")[2]
        fastFeriadoNovo.mes = f.split("-")[1]

        this.fastFeriados.total.forEach(e => {
          if (e.dia == fastFeriadoNovo.dia && e.mes == fastFeriadoNovo.mes && e.ano_base == fastFeriadoNovo.ano_base) fastFeriadoNovo.id_calendario = e.id_calendario
        })
        
        if (fastFeriadoNovo.id_calendario) {
          await this.promiseExcluirFastFeriado(fastFeriadoNovo); 
        }               

        if ((index + 1) == datas.length) {
          this.exibeToasty("Dias removidos com sucesso", "success")
          this.hideModal('modalDiaPersonalizadoExcluir')
          this.getfastFeriados(this.$route.params.id_plataforma, this.fastAnoSelecionado)           
          this.fastBtnLoading = false
        }    
      })
      
    },
    async promiseCriarFastFeriado(obj){
      return new Promise(async (resolve, reject) => {
        this.promisePostFastApi(obj, "api/fast_secretaria_feriado/insere").then((res) => {  
          resolve(1)
        }).catch((e) => {
            this.exibeToasty("Erro ao inserir dia", "error");
            this.fastBtnLoading = false
            reject(1)
        }); 
      });
    },
    async promiseExcluirFastFeriado(obj){
      return new Promise(async (resolve, reject) => {
        this.promisePostFastApi(obj, "api/fast_secretaria_feriado/exclui").then((res) => {  
          resolve(1)
        }).catch((e) => {
            this.exibeToasty("Erro ao remover dia", "error");
            this.fastBtnLoading = false
            reject(1)
        }); 
      });
    },
    async excluirFastFeriado(){
      this.fastBtnLoading = true   
      this.promisePostFastApi(this.fastFeriadoNovo, "api/fast_secretaria_feriado/exclui").then(res => {
        this.fastBtnLoading = false
        this.exibeToasty("Dias removido com sucesso", "success")  
        this.hideModal('modalFeriado')
            this.getfastFeriados(this.$route.params.id_plataforma, this.fastFeriadoNovo.ano_base)
            
            this.fastFeriadoNovo = {
              id_calendario: 0,
              nome_feriado: "",
              ano_base: 0,
              id_plataforma: this.$route.params.id_plataforma,
              data_fixa: false,
              data_flutuante: false,
              dia: 0,
              mes: 0,
              deriva_plataforma_filha: false
            }
      }).catch(e => {
          this.exibeToasty("Erro ao remover", "error");
          this.fastBtnLoading = false
      });
    },
    async editarFastFeriado(){
      this.fastBtnLoading = true   
      this.promisePostFastApi(this.fastFeriadoNovo, "api/fast_secretaria_feriado/atualiza").then(res => {
        this.fastBtnLoading = false
        this.exibeToasty("Feriado atualizado com sucesso", "success")  
        this.hideModal('modalFeriado')
            this.getfastFeriados(this.$route.params.id_plataforma, this.fastFeriadoNovo.ano_base)            
            this.fastFeriadoNovo = {
              id_calendario: 0,
              nome_feriado: "",
              ano_base: 0,
              id_plataforma: this.$route.params.id_plataforma,
              data_fixa: false,
              data_flutuante: false,
              dia: 0,
              mes: 0,
              deriva_plataforma_filha: false
            }
      }).catch(e => {
          this.exibeToasty("Erro ao salvar alterações", "error");
          this.fastBtnLoading = false
      });
    },
    verificaCal(e){
      //console.log("verificaCal", e)
      const mes = e.ref.split("_")[1]
      const ano = e.ref.split("_")[2]
      //this.$refs.fastCalendario_1_2021.ChooseDate(`1/${mes}/${ano}`)
      //console.log("this.$refs", e)
    }
  },
};
</script>

<style scope>
.cal-feriado-marcado {
  background-color: #E10000 !important;
  color: #fff;
}
.fast-cursor-pointer:hover {
  background-color: #eee;
}
.fast-ano-selecionado {
  background-color: #eee;
}
.vfc-disabled {
  opacity: 0;
}
</style>
